<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        Detail Point Transaction
        <div class="card-header-actions">
          <a href="point-transaction" class="card-header-action" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <br>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row class="mb-4">
          <b-col sm="4">
            <h6 class="label">Transaction Date</h6>
            <div class="mb-4"><strong>{{this.date}}</strong></div>
            <h6 class="label">Title</h6>
            <div class="mb-4"><strong>{{this.title}}</strong></div>
            <h6 class="label">Benefit</h6>
            <div class="mb-4"><strong>{{this.benefit}}</strong></div>
            <h6 class="label">Gold Buy Price</h6>
            <div class="mb-4"><strong>{{this.buyingRate}}</strong></div>
            <h6 class="label">Transaksi User</h6>
            <div class="mb-4"><strong>{{this.troopsName}} ({{ this.troopsEmail }})</strong></div>
          </b-col>
          <b-col sm="4">
            <h6 class="label">Penerima Benefit</h6>
            <div class="mb-4"><strong>{{this.name}} ({{ this.email }})</strong></div>
            <h6 class="label">Transaction Type</h6>
            <div class="mb-4"><strong>{{this.type}}</strong></div>
            <h6 class="label">Gold Amout</h6>
            <div class="mb-4"><strong>{{this.goldAmount}}</strong></div>
            <h6 class="label">Gold Sell Price</h6>
            <div class="mb-4"><strong>{{this.sellingRate}}</strong></div>
            <h6 class="label">Gen Transaksi User</h6>
            <div class="mb-4"><strong>{{this.genTroops}}</strong></div>
          </b-col>
          <b-col sm="4">
            <h6 class="label">Kode Referral</h6>
            <div class="mb-4"><strong>{{this.referral}}</strong></div>
            <h6 class="label">Points</h6>
            <div class="mb-4"><strong>{{this.point}}</strong></div>
            <h6 class="label">IDR Amount</h6>
            <div class="mb-4"><strong>{{this.idrAmount}}</strong></div>
            <h6 class="label">Invoice No</h6>
            <div class="mb-4"><strong>{{this.invoiceNo}}</strong></div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'detail-point-transaction',
  data() {
    return {
      date: '',
      name: '',
      email: '',
      referral: '',
      title: '',
      type: '',
      point: '',
      benefit: '',
      goldAmount: '',
      idrAmount: '',
      buyingRate: '',
      sellingRate: '',
      invoiceNo: '',
      troopsName: '',
      troopsEmail: '',
      genTroops: '',
      isLoading: false,
      errors: {
        code: '',
        status: '',
        message: ''
      }
    }
  },
  created () {
    this.$http.get(`army/transaction-point/detail?id=` + this.$route.params.id)
    .then((result) => {
      const payload = result.data.data;
      this.date = payload.transaction_date;
      this.name = payload.users.name;
      this.email = payload.users.email;
      this.referral = payload.referral_code;
      this.title = payload.title;
      this.type = payload.transaction_type;
      this.point = payload.point;
      this.benefit = payload.benefit;
      this.goldAmount = payload.gold_amount;
      this.idrAmount = payload.gold_currency;
      this.buyingRate = payload.buying_rate;
      this.sellingRate = payload.selling_rate;
      this.invoiceNo = payload.invoice_number;
      this.troopsName = payload.troops.name;
      this.troopsEmail = payload.troops.email;
      this.genTroops = payload.gen_troops;
    }).catch((error) => {
      if (error.response) {
        this.errors.code = error.response.status;
        this.errors.message = error.response.message;
        this.errors.status = error.response.data.meta.code;
      }
    })
  }
}
</script>

<style>
.label {
  color: #606060;
}
</style>
<style src='spinkit/scss/spinkit.scss' lang='scss'></style>